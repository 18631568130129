import React from 'react';
import { Heading } from 'components/typography';
import tw from 'twin.macro';

interface Props {
  title?: React.ReactNode;
  extraRight?: React.ReactNode;
  id?: string;
  className?: string;
}

export const Card: React.FC<Props> = ({
  children,
  title,
  extraRight,
  id,
  className,
}) => {
  return (
    <div className={className} css={tw`p-4 bg-white`} id={id}>
      {(title || extraRight) && (
        <div css={tw`flex justify-between mb-4`}>
          <Heading as="h2" css={tw`text-lg text-gray-800 font-semibold`}>
            {title}
          </Heading>
          {extraRight}
        </div>
      )}
      {children}
    </div>
  );
};
