import React from 'react';
import { NextPage, GetServerSideProps } from 'next';
import APIs from 'libs/apis';
import { Seller } from 'types/schema';
import { ErrorPage } from 'components/pages/404';
import SellerPage from 'components/pages/seller/SellerPage';
import { createUTMHeaderFromQuery } from 'utils/request';

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
  try {
    const data = await APIs.Seller.getBySlug(
      'artzy',
      createUTMHeaderFromQuery(query),
    );

    return { props: { data } };
  } catch (error) {
    return { props: { status: 404 } };
  }
};

const HomePage: NextPage<{
  data: { data: Seller };
  status: number;
}> = ({ data, status }) => {
  if (status === 404) {
    return <ErrorPage />;
  }

  return <SellerPage data={data?.data} />;
};

export default HomePage;
